import React, { useState, useRef, useEffect, useMemo } from 'react';
import './App.css';
import { marked } from 'marked';
import GoogleAnalytics from './GoogleAnalytics';

interface Card {
  id: number;
  content: string;
  position: { x: number; y: number };
  conversationHistory: string[];
  title?: string;
  width?: number;
  height?: number;
}

interface Image {
  id: number;
  url: string;
  position: { x: number; y: number };
  description?: string;
}

interface Command {
  undo: () => void;
  redo: () => void;
}

interface PDF {
  id: number;
  url: string;
  position: { x: number; y: number };
  width: number;
  height: number;
}

interface Link {
  fromId: number;
  toId: number;
}

// Add new interface for vision messages
interface VisionMessage {
  role: string;
  content: Array<{
    type: string;
    text?: string;
    image_url?: {
      url: string;
    };
  }>;
}

// Add these new interfaces near the top with other interfaces
interface ResizeHandle {
  cursor: string;
  position: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
}

interface ResizeStart {
  x: number;
  y: number;
  width: number;
  height: number;
  handle: string;
  originalPosition: { x: number; y: number };
}

// Add these new interfaces
interface DrawingState {
  isDrawing: boolean;
  startCardId: number | null;
  endPosition: { x: number; y: number } | null;
}

function App() {
  const [cards, setCards] = useState<Card[]>(() => {
    const savedCards = localStorage.getItem('cards');
    // Check if this is the first session
    if (!savedCards) {
      // Return initial state with intro cards
      return [
        {
          id: Date.now(),
          content: "### This is trails... \n\nA canvas where you can:\n\n- Add cards with text (double-click anywhere... you can also drag the bottom right corner to resize cards)\n- Upload images and PDFs\n- Chat with AI models\n- Connect and organize your thoughts\n\n*Double-click this card to edit it, or click anywhere on the canvas to create new cards. Select and delete this card if you're done with it.*",
          position: { x: window.innerWidth/2 - 450, y: window.innerHeight/2 - 350 }, // Moved up 200px
          conversationHistory: [],
          width: 300,
          height: 370,
        },
        {
          id: Date.now() + 1,
          content: "### If you want to use AI...\n\nYou'll need an OpenAI API key. Or message https://x.com/@0sebo and they'll send you one.\n\nRemember to *double-click this card to edit it, or click anywhere on the canvas to create new cards. Select and delete this card if you're done with it.*",
          position: { x: window.innerWidth/2 - 100, y: window.innerHeight/2 - 350 }, // Moved up 200px
          conversationHistory: [],
          width: 300,
          height: 300,
        }
      ];
    }
    return JSON.parse(savedCards);
  });
  const [images, setImages] = useState<Image[]>([]);
  const [inputPosition, setInputPosition] = useState({ x: 50, y: 50 });
  const [isDragging, setIsDragging] = useState(false);
  const [draggedCardId, setDraggedCardId] = useState<number | null>(null);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [inputValue, setInputValue] = useState("");
  const [editingCardId, setEditingCardId] = useState<number | null>(null);
  const [draggedImageId, setDraggedImageId] = useState<number | null>(null);
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null);
  const [undoStack, setUndoStack] = useState<Command[]>([]);
  const [redoStack, setRedoStack] = useState<Command[]>([]);
  const [activeCardId, setActiveCardId] = useState<number | null>(null);
  const [uploadingImageForCard, setUploadingImageForCard] = useState<number | null>(null);
  const [tempCard, setTempCard] = useState<Card | null>(null);
  const [tempCardContent, setTempCardContent] = useState('');
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);
  const [draggedElementId, setDraggedElementId] = useState<number | null>(null);
  const [clipboard, setClipboard] = useState<{ type: 'card' | 'image', data: Card | Image } | null>(null);
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [duplicatedItemId, setDuplicatedItemId] = useState<number | null>(null);
  const [focusedCardId, setFocusedCardId] = useState<number | null>(null);
  const [renderedContents, setRenderedContents] = useState<{ [key: number]: string }>({});
  const [activeInputCardId, setActiveInputCardId] = useState<number | null>(null);
  const [resizingCardId, setResizingCardId] = useState<number | null>(null);
  const [resizeStart, setResizeStart] = useState<ResizeStart | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [generatingCardId, setGeneratingCardId] = useState<number | null>(null);
  const [hoveredCardId, setHoveredCardId] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [pdfs, setPdfs] = useState<PDF[]>([]);
  const [links, setLinks] = useState<Link[]>(() => {
    const savedLinks = localStorage.getItem('links');
    return savedLinks ? JSON.parse(savedLinks) : [];
  });

  // Add this new state to track generated cards
  const [generatedCardIds, setGeneratedCardIds] = useState<Set<number>>(() => {
    const savedGeneratedCardIds = localStorage.getItem('generatedCardIds');
    return new Set(savedGeneratedCardIds ? JSON.parse(savedGeneratedCardIds) : []);
  });

  // Add this new state to track cards waiting for input
  const [pendingInputCards, setPendingInputCards] = useState<Set<number>>(new Set());

  // Add this new state to track newly created cards
  const [newCardId, setNewCardId] = useState<number | null>(null);

  // Add this new state to store the static rectangles
  const [staticRectangles, setStaticRectangles] = useState<{ [key: string]: any[] }>({});

  // Add these new state variables near the top where other state variables are declared
  const [openAIKey, setOpenAIKey] = useState(() => localStorage.getItem('openAIKey') || '');
  const [selectedModel, setSelectedModel] = useState(() => localStorage.getItem('selectedModel') || 'openai');
  const [showConfigModal, setShowConfigModal] = useState(false);

  // Add this new state near your other state declarations
  const [hoveredImageId, setHoveredImageId] = useState<number | null>(null);

  // Add a new state for tracking which image is being interpreted
  const [interpretingImageId, setInterpretingImageId] = useState<number | null>(null);

  // Add these new state variables
  const [drawingState, setDrawingState] = useState<DrawingState>({
    isDrawing: false,
    startCardId: null,
    endPosition: null,
  });

  const appRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isInitialMount = useRef(true);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Add these new helper functions near the top of your component
  const isElementInViewport = (position: { x: number; y: number }, width: number, height: number): boolean => {
    // Convert viewport bounds to canvas space
    const viewportLeft = -pan.x / scale;
    const viewportTop = -pan.y / scale;
    const viewportRight = (window.innerWidth - pan.x) / scale;
    const viewportBottom = (window.innerHeight - pan.y) / scale;

    // Add padding to prevent popping at edges (adjust the 500 value as needed)
    const padding = 500;
    
    return !(position.x + width < viewportLeft - padding ||
             position.x > viewportRight + padding ||
             position.y + height < viewportTop - padding ||
             position.y > viewportBottom + padding);
  };

  // Remove the global preventDefault effect
  useEffect(() => {
    // Remove or comment out these lines
    // const preventDefault = (e: Event) => e.preventDefault();
    // document.addEventListener('selectstart', preventDefault);
    // return () => {
    //   document.removeEventListener('selectstart', preventDefault);
    // };
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];
      const isMobileDevice = mobileKeywords.some(keyword => userAgent.includes(keyword));
      const isNarrowScreen = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(isMobileDevice || isNarrowScreen);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    // Check orientation change for mobile devices
    window.addEventListener('orientationchange', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener('orientationchange', checkMobile);
    };
  }, []);

  useEffect(() => {
    const renderMarkdown = async () => {
      const renderer = new marked.Renderer();
      
      // Update the link renderer to handle different argument patterns
      renderer.link = ((href: string, title: string | null, text: string) => {
        // Check if the first argument is an object (new marked behavior)
        if (typeof href === 'object') {
          const linkObject = href as { href: string; title: string | null; text: string };
          href = linkObject.href;
          title = linkObject.title;
          text = linkObject.text;
        }

        return `<a href="${href}" title="${title || ''}" target="_blank" rel="noopener noreferrer">${text}</a>`;
      }) as any;

      marked.setOptions({ renderer });

      const newRenderedContents: { [key: number]: string } = {};
      for (const card of cards) {
        newRenderedContents[card.id] = await marked(card.content || 'Double-click to edit');
      }
      setRenderedContents(newRenderedContents);
    };

    renderMarkdown();
  }, [cards]);

  useEffect(() => {
    console.log('Component mounted, loading saved state...');
    const loadSavedState = () => {
      try {
        const savedState = localStorage.getItem('canvasState');
        console.log('Raw saved state:', savedState);

        if (savedState) {
          const parsedState = JSON.parse(savedState);
          console.log('Parsed state:', parsedState);

          if (parsedState.cards && Array.isArray(parsedState.cards)) {
            setCards(parsedState.cards);
            console.log('Set cards:', parsedState.cards);
          }
          if (parsedState.images && Array.isArray(parsedState.images)) {
            setImages(parsedState.images);
            console.log('Set images:', parsedState.images);
          }
          if (parsedState.pdfs && Array.isArray(parsedState.pdfs)) {
            setPdfs(parsedState.pdfs);
            console.log('Set pdfs:', parsedState.pdfs);
          }
          if (parsedState.inputPosition) {
            setInputPosition(parsedState.inputPosition);
            console.log('Set inputPosition:', parsedState.inputPosition);
          }
        } else {
          console.log('No saved state found in localStorage');
        }
      } catch (error) {
        console.error('Error loading data from localStorage:', error);
      }
    };

    loadSavedState();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      console.log('Initial mount, skipping save');
      isInitialMount.current = false;
    } else {
      console.log('State changed, saving...');
      saveState();
    }
  }, [cards, images, pdfs, inputPosition]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      if (e.ctrlKey) {
        const rect = appRef.current?.getBoundingClientRect();
        if (!rect) return;
        
        // Get raw mouse position relative to viewport
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        // Get the point in the document where the mouse is pointing
        const pointX = (mouseX - pan.x) / scale;
        const pointY = (mouseY - pan.y) / scale;

        const zoomFactor = 0.05; // Increased from 0.03 for faster zooming
        const delta = e.deltaY > 0 ? -1 : 1;
        
        setScale(prevScale => {
          const newScale = prevScale * (1 + delta * zoomFactor);
          const boundedNewScale = Math.min(Math.max(newScale, 0.25), 4);
          
          // Calculate new pan position to keep the point under mouse fixed
          setPan(prevPan => ({
            x: mouseX - pointX * boundedNewScale,
            y: mouseY - pointY * boundedNewScale,
          }));
          
          return boundedNewScale;
        });
      } else {
        setPan(prevPan => ({
          x: prevPan.x - e.deltaX * 0.5,
          y: prevPan.y - e.deltaY * 0.5,
        }));
      }
    };

    const appElement = appRef.current;
    if (appElement) {
      appElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (appElement) {
        appElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [pan, scale]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'v' && focusedCardId !== null) {
        e.preventDefault();
        navigator.clipboard.readText().then(pastedText => {
          // Try to parse as JSON first
          try {
            JSON.parse(pastedText);
            // If it parses successfully, it's probably our card format, so ignore it
            // when pasting into a card's content
            return;
          } catch {
            // If it fails to parse as JSON, it's regular text, so paste it
            if (editingCardId === focusedCardId) {
              setTempCardContent(prev => prev + pastedText);
            } else {
              setCards(prevCards =>
                prevCards.map(card =>
                  card.id === focusedCardId
                    ? { ...card, content: card.content + pastedText }
                    : card
                )
              );
            }
          }
        });
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [focusedCardId, editingCardId]);

  useEffect(() => {
    const handleGlobalKeyDown = (e: KeyboardEvent) => {
      if (editingCardId !== null) {
        if ((e.metaKey || e.ctrlKey) && e.key === 'b') {
          console.log('Bold command detected');
          e.preventDefault();
          formatText('bold');
        } else if ((e.metaKey || e.ctrlKey) && e.key === 'i') {
          console.log('Italic command detected');
          e.preventDefault();
          formatText('italic');
        }
      }
    };

    document.addEventListener('keydown', handleGlobalKeyDown);

    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [editingCardId]);

  // Add useEffect hooks to save state changes to localStorage
  useEffect(() => {
    localStorage.setItem('cards', JSON.stringify(cards));
  }, [cards]);

  useEffect(() => {
    localStorage.setItem('links', JSON.stringify(links));
  }, [links]);

  useEffect(() => {
    localStorage.setItem('generatedCardIds', JSON.stringify(Array.from(generatedCardIds)));
  }, [generatedCardIds]);

  // Add this new effect to optimize rerendering
  useEffect(() => {
    let rafId: number;
    let lastPan = { x: pan.x, y: pan.y };
    let lastScale = scale;

    const checkForUpdates = () => {
      if (lastPan.x !== pan.x || lastPan.y !== pan.y || lastScale !== scale) {
        lastPan = { x: pan.x, y: pan.y };
        lastScale = scale;
        // Force a rerender only when pan or scale actually changes
        setForceUpdate(prev => !prev);
      }
      rafId = requestAnimationFrame(checkForUpdates);
    };

    rafId = requestAnimationFrame(checkForUpdates);
    return () => cancelAnimationFrame(rafId);
  }, []);

  // Add this new state near your other state declarations
  const [forceUpdate, setForceUpdate] = useState(false);

  // Conditional rendering for mobile
  if (isMobile) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        fontFamily: 'Georgia, "Times New Roman", Times, serif',
        fontSize: '24px',
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#f0f0f0',
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        left: 0,
      }}>
        <div>
          <h1 style={{ marginBottom: '20px' }}>Desktop Only</h1>
        </div>
      </div>
    );
  }

  // Add this near the top of your component, where other constants are defined
  const wiggleKeyframes = `
    @keyframes subtleWiggle {
      0% { transform: rotate(0deg); }
      25% { transform: rotate(0.5deg); }
      75% { transform: rotate(-0.5deg); }
      100% { transform: rotate(0deg); }
    }
  `;

  const generateButtonKeyframes = `
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  `;

  const MODEL = "llama3.2";

  const addCommand = (command: Command) => {
    setUndoStack(prevStack => [...prevStack, command]);
    setRedoStack([]); // Clear redo stack when a new action is performed
  };

  const undo = () => {
    if (undoStack.length > 0) {
      const command = undoStack[undoStack.length - 1];
      command.undo();
      setUndoStack(prevStack => prevStack.slice(0, -1));
      setRedoStack(prevStack => [...prevStack, command]);
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const command = redoStack[redoStack.length - 1];
      command.redo();
      setRedoStack(prevStack => prevStack.slice(0, -1));
      setUndoStack(prevStack => [...prevStack, command]);
    }
  };

  // Save state to localStorage
  const saveState = () => {
    console.log('Saving state...');
    console.log('Current cards:', cards);
    console.log('Current images:', images);
    console.log('Current pdfs:', pdfs);
    console.log('Current inputPosition:', inputPosition);
    
    try {
      const stateToSave = {
        cards,
        images,
        pdfs,
        inputPosition,
      };
      localStorage.setItem('canvasState', JSON.stringify(stateToSave));
      console.log('Saved state to localStorage:', stateToSave);
    } catch (error) {
      console.error('Error saving data to localStorage:', error);
    }
  };

  const handleMouseDown = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    e.preventDefault();

    // If cmd/ctrl is pressed, we only want to handle drawing
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    setIsDragging(true);
    setDraggedElementId(id);
    setDragStart({
      x: e.clientX,
      y: e.clientY
    });

    // Set the draggedCardId if it's a card
    const card = cards.find(c => c.id === id);
    if (card && editingCardId !== id) {
      setDraggedCardId(id);
    }

    // Update this part to include PDFs
    if (e.altKey) {
      setIsDuplicating(true);
      setDuplicatedItemId(null);
      setSelectedItems(new Set([id]));
    } else {
      setIsDuplicating(false);
      if (!e.shiftKey) {
        if (!selectedItems.has(id)) {
          setSelectedItems(new Set([id]));
        }
      } else {
        setSelectedItems(prev => {
          const newSet = new Set(prev);
          if (newSet.has(id)) {
            newSet.delete(id);
          } else {
            newSet.add(id);
          }
          return newSet;
        });
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (drawingState.isDrawing) {
      setDrawingState(prev => ({
        ...prev,
        endPosition: {
          x: (e.clientX - pan.x) / scale,
          y: (e.clientY - pan.y) / scale
        }
      }));
    } else if (isDragging && dragStart && draggedElementId !== null) {
      const dx = (e.clientX - dragStart.x) / scale;
      const dy = (e.clientY - dragStart.y) / scale;

      if (isDuplicating && duplicatedItemId === null) {
        // Create a single duplicate
        const cardToDuplicate = cards.find(card => card.id === draggedElementId);
        const imageToDuplicate = images.find(image => image.id === draggedElementId);

        if (cardToDuplicate) {
          const newCard = {
            ...cardToDuplicate,
            id: Date.now(),
            position: {
              x: cardToDuplicate.position.x + dx,
              y: cardToDuplicate.position.y + dy
            }
          };
          setCards(prevCards => [...prevCards, newCard]);
          setDuplicatedItemId(newCard.id);
          setDraggedElementId(newCard.id);
          setSelectedItems(new Set([newCard.id]));
        } else if (imageToDuplicate) {
          const newImage = {
            ...imageToDuplicate,
            id: Date.now(),
            position: {
              x: imageToDuplicate.position.x + dx,
              y: imageToDuplicate.position.y + dy
            }
          };
          setImages(prevImages => [...prevImages, newImage]);
          setDuplicatedItemId(newImage.id);
          setDraggedElementId(newImage.id);
          setSelectedItems(new Set([newImage.id]));
        }
      } else {
        // Move selected items
        setCards(prevCards =>
          prevCards.map(card =>
            selectedItems.has(card.id)
              ? {
                  ...card,
                  position: {
                    x: card.position.x + dx,
                    y: card.position.y + dy
                  }
                }
              : card
          )
        );

        setImages(prevImages =>
          prevImages.map(image =>
            selectedItems.has(image.id)
              ? {
                  ...image,
                  position: {
                    x: image.position.x + dx,
                    y: image.position.y + dy
                  }
                }
              : image
          )
        );
      }

      setPdfs(prevPdfs =>
        prevPdfs.map(pdf =>
          selectedItems.has(pdf.id)
            ? {
                ...pdf,
                position: {
                  x: pdf.position.x + dx,
                  y: pdf.position.y + dy
                }
              }
            : pdf
        )
      );

      // After updating card positions, update the colors of the squares
      setStaticRectangles(prevRectangles => {
        const newRectangles = { ...prevRectangles };
        Object.keys(newRectangles).forEach(key => {
          newRectangles[key] = newRectangles[key].map(square => ({
            ...square,
            color: `hsl(${Math.random() * 360}, 70%, 70%)`,
          }));
        });
        return newRectangles;
      });

      setDragStart({
        x: e.clientX,
        y: e.clientY
      });
    } else if (resizingCardId !== null) {
      handleResize(e);
    }
  };

  const handleMouseUp = () => {
    setDrawingState({
      isDrawing: false,
      startCardId: null,
      endPosition: null
    });
    setIsDragging(false);
    setDraggedElementId(null);
    setDragStart(null);
    setDraggedCardId(null); // Reset the draggedCardId
    handleResizeEnd();
    if (isDuplicating) {
      setIsDuplicating(false);
      if (duplicatedItemId !== null) {
        setSelectedItems(new Set([duplicatedItemId]));
      }
      setDuplicatedItemId(null);
    }
  };

  const handleGenerate = (text: string) => {
    console.log('handleGenerate called with text:', text);
    if (text.trim()) {
      const newCard: Card = {
        id: Date.now(),
        content: text,
        position: { x: inputPosition.x + 320, y: inputPosition.y },
        conversationHistory: [],
      };
      console.log('Creating new card:', newCard);
      
      // Generate response based on selected model
      const generateResponse = async () => {
        console.log(`Using ${selectedModel} model for generation`);
        const response = selectedModel === 'ollama'
          ? await generateOllamaResponse(newCard.id, text)
          : await generateChatGPTResponse(newCard.id, text);
        
        if (response) {
          console.log('Generated response:', response);
          setCards(prevCards => {
            const responseCard: Card = {
              id: Date.now(),
              content: response,
              position: { x: newCard.position.x + 260, y: newCard.position.y },
              conversationHistory: [text, response],
            };
            console.log('Creating response card:', responseCard);
            return [...prevCards, newCard, responseCard];
          });
        } else {
          // If no response, just create the initial card
          setCards(prevCards => {
            console.log('No response generated, creating only initial card');
            return [...prevCards, newCard];
          });
        }
      };

      generateResponse();
    }
  };

  const handleDeleteItem = () => {
    if (selectedCardId !== null) {
      const cardToDelete = cards.find(card => card.id === selectedCardId);
      if (cardToDelete) {
        const deleteCardCommand: Command = {
          undo: () => setCards(prevCards => [...prevCards, cardToDelete]),
          redo: () => setCards(prevCards => prevCards.filter(card => card.id !== selectedCardId))
        };
        addCommand(deleteCardCommand);
        deleteCardCommand.redo();
      }
      setSelectedCardId(null);
    } else if (selectedImageId !== null) {
      const imageToDelete = images.find(image => image.id === selectedImageId);
      if (imageToDelete) {
        const deleteImageCommand: Command = {
          undo: () => setImages(prevImages => [...prevImages, imageToDelete]),
          redo: () => setImages(prevImages => prevImages.filter(image => image.id !== selectedImageId))
        };
        addCommand(deleteImageCommand);
        deleteImageCommand.redo();
      }
      setSelectedImageId(null);
    }
  };

  const handleDeleteSelectedItems = () => {
    // Don't delete if an input is active
    if (activeInputCardId !== null) return;

    const cardIdsToDelete = new Set(cards.filter(card => selectedItems.has(card.id)).map(card => card.id));
    const imageIdsToDelete = new Set(images.filter(image => selectedItems.has(image.id)).map(image => image.id));
    const pdfIdsToDelete = new Set(pdfs.filter(pdf => selectedItems.has(pdf.id)).map(pdf => pdf.id));

    const deletedCards = cards.filter(card => cardIdsToDelete.has(card.id));
    const deletedImages = images.filter(image => imageIdsToDelete.has(image.id));
    const deletedPdfs = pdfs.filter(pdf => pdfIdsToDelete.has(pdf.id));

    const deleteCommand: Command = {
      undo: () => {
        setCards(prevCards => [...prevCards, ...deletedCards]);
        setImages(prevImages => [...prevImages, ...deletedImages]);
        setPdfs(prevPdfs => [...prevPdfs, ...deletedPdfs]);
        setSelectedItems(new Set([...deletedCards.map(card => card.id), ...deletedImages.map(image => image.id), ...deletedPdfs.map(pdf => pdf.id)]));
      },
      redo: () => {
        setCards(prevCards => prevCards.filter(card => !cardIdsToDelete.has(card.id)));
        setImages(prevImages => prevImages.filter(image => !imageIdsToDelete.has(image.id)));
        setPdfs(prevPdfs => prevPdfs.filter(pdf => !pdfIdsToDelete.has(pdf.id)));
        setSelectedItems(new Set());
      }
    };

    addCommand(deleteCommand);
    deleteCommand.redo();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Skip global keyboard handling if we're editing a card
    if (editingCardId !== null) {
      return;
    }

    console.log('Key pressed:', e.key, 'Meta key:', e.metaKey, 'Ctrl key:', e.ctrlKey);

    // Handle delete if no input is active
    if ((e.key === 'Delete' || e.key === 'Backspace') && selectedItems.size > 0 && activeInputCardId === null) {
      e.preventDefault();
      handleDeleteSelectedItems();
    } else if (e.ctrlKey || e.metaKey) {
      if (e.key === 'a' && activeInputCardId === null) {
        e.preventDefault();
        const allIds = new Set([...cards.map(card => card.id), ...images.map(image => image.id)]);
        setSelectedItems(allIds);
      } else if (e.key === 'z') {
        e.preventDefault();
        undo();
      } else if (e.key === 'y') {
        e.preventDefault();
        redo();
      }
    }
  };

  // Modify handleCardContentKeyDown
  const handleCardContentKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>, cardId: number) => {
    // Allow all default behavior when cmd/ctrl is pressed
    if (e.metaKey || e.ctrlKey) {
      e.stopPropagation(); // Stop propagation but allow default behavior
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleCardBlur();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setEditingCardId(null);
      setTempCardContent('');
    }
    
    e.stopPropagation();
  };

  // First, let's add the formatText function
  const formatText = (type: 'bold' | 'italic') => {
    if (!textareaRef.current) return;
    
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);
    
    const marker = type === 'bold' ? '**' : '_';
    const newText = textarea.value.substring(0, start) + 
                   marker + selectedText + marker + 
                   textarea.value.substring(end);
    
    setTempCardContent(newText);
  };

  // Update handleCardContentChange
  const handleCardContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setTempCardContent(newContent);
    
    if (editingCardId) {
      setCards(prevCards =>
        prevCards.map(card =>
          card.id === editingCardId
            ? { ...card, content: newContent }
            : card
        )
      );
      
      // Update rendered content
      // Force marked to return a string synchronously
      const renderedContent = marked.parse(newContent, { async: false }) as string;
      setRenderedContents(prev => ({
        ...prev,
        [editingCardId]: renderedContent
      }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Input changed:", e.target.value);
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleGenerate(inputValue); // Pass inputValue here
    }
  };

  const handleCardClick = (e: React.MouseEvent, cardId: number) => {
    e.stopPropagation();
    if (!isDragging) {
      // Don't reset selection if we're currently editing
      if (editingCardId !== cardId) {
        setSelectedCardId(cardId);
        setEditingCardId(null);
        setSelectedImageId(null);
      }
    }
  };

  const handleCardDoubleClick = (e: React.MouseEvent, cardId: number) => {
    e.stopPropagation();
    e.preventDefault();
    
    // If we're already editing this card, don't reset the content
    if (editingCardId === cardId) {
      return;
    }

    setEditingCardId(cardId);
    const card = cards.find(c => c.id === cardId);
    setTempCardContent(card?.content || '');
  };

  const saveCardContent = (cardId: number) => {
    setCards(prevCards =>
      prevCards.map(card =>
        card.id === cardId ? { ...card, content: tempCardContent } : card
      )
    );
    setEditingCardId(null);
    setTempCardContent('');
  };

  // Add this new function near the top of the component
  const isTextSelected = () => {
    const selection = window.getSelection();
    return selection && selection.toString().length > 0;
  };

  // Modify the handleCardBlur function
  const handleCardBlur = (e?: React.FocusEvent) => {
    // Add a small delay to check for text selection
    setTimeout(() => {
      const selection = window.getSelection();
      const isTextCurrentlySelected = selection && selection.toString().length > 0;
      
      // If text is selected, refocus the textarea
      if (isTextCurrentlySelected && textareaRef.current) {
        textareaRef.current.focus();
        return;
      }

      // Only proceed with blur if no text is selected
      if (!isTextCurrentlySelected && editingCardId !== null) {
        setCards(prevCards => prevCards.map(card => 
          card.id === editingCardId ? { ...card, content: tempCardContent } : card
        ));
        setEditingCardId(null);
        setTempCardContent('');
      }
    }, 0);
  };

  const handleCanvasDoubleClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      const cardWidth = 250;
      const cardHeight = 100;

      const newCard: Card = {
        id: Date.now(),
        content: '',
        position: {
          x: (e.clientX - pan.x) / scale - cardWidth / 2,
          y: (e.clientY - pan.y) / scale
        },
        conversationHistory: [],
      };
      
      setCards(prevCards => [...prevCards, newCard]);
      setTempCardContent('');
      setEditingCardId(newCard.id); // Immediately set editing mode
      setNewCardId(newCard.id);
      
      setTimeout(() => setNewCardId(null), 100);
    }
  };

  const handleCanvasClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleCardBlur();
      setSelectedItems(new Set());
      setEditingCardId(null);
    }
  };

  // Add new function to handle vision-based generation
  const generateVisionResponse = async (imageUrl: string, prompt: string = "What's in this image?") => {
    if (!openAIKey) {
      return "Please set up your OpenAI API key first to use vision features.";
    }

    try {
      // Convert data URL to base64 string
      const base64Image = imageUrl.split(',')[1];

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${openAIKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",  // Updated to correct model name
          messages: [
            {
              role: "user",
              content: [
                { type: "text", text: prompt },
                {
                  type: "image_url",
                  image_url: {
                    url: `data:image/jpeg;base64,${base64Image}`
                  }
                }
              ]
            }
          ],
          max_tokens: 300
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('OpenAI API error:', errorData);
        throw new Error(`HTTP error! status: ${response.status}, message: ${JSON.stringify(errorData)}`);
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error('Error generating vision response:', error);
      return `Error analyzing image: ${error instanceof Error ? error.message : 'Unknown error occurred'}`;
    }
  };

  // Modify handleImageUpload to create a link
  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const imageUrl = event.target?.result as string;
        const newImage = {
          id: Date.now(),
          url: imageUrl,
          position: {
            x: (inputPosition.x - pan.x) / scale,
            y: (inputPosition.y - pan.y) / scale,
          },
        };
        
        // Add the image first
        setImages(prevImages => [...prevImages, newImage]);

        // Generate vision response
        const description = await generateVisionResponse(imageUrl);
        
        // Create a new card with the vision response
        const responseCard: Card = {
          id: Date.now() + 1,
          content: description,
          position: {
            x: newImage.position.x + 260,
            y: newImage.position.y,
          },
          conversationHistory: [],
          width: 250,
          height: 200,
        };

        setCards(prevCards => [...prevCards, responseCard]);
        
        // Create a link between the image and the response card
        setLinks(prevLinks => [...prevLinks, {
          fromId: newImage.id,
          toId: responseCard.id
        }]);
      };
      reader.readAsDataURL(file);
    }
  };

  // Modify analyzeExistingImage to create a link
  const analyzeExistingImage = async (imageId: number) => {
    const image = images.find(img => img.id === imageId);
    if (!image) return;

    setInterpretingImageId(imageId); // Set loading state
    
    try {
      const description = await generateVisionResponse(image.url);
      
      const responseCard: Card = {
        id: Date.now(),
        content: description,
        position: {
          x: image.position.x + 260,
          y: image.position.y,
        },
        conversationHistory: [],
        width: 250,
        height: 200,
      };

      setCards(prevCards => [...prevCards, responseCard]);
      
      // Create a link between the image and the response card
      setLinks(prevLinks => [...prevLinks, {
        fromId: imageId,
        toId: responseCard.id
      }]);
    } finally {
      setInterpretingImageId(null); // Clear loading state
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current?.click();
  };

  const handleCardImageUpload = (cardId: number, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadImage = (file: File) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageUrl = event.target?.result as string;
        const card = cards.find(c => c.id === cardId);
        if (card) {
          const newImage: Image = {
            id: Date.now(),
            url: imageUrl,
            position: {
              x: card.position.x,
              y: card.position.y,
            },
          };
          setImages(prevImages => [...prevImages, newImage]);
          setCards(prevCards => prevCards.filter(c => c.id !== cardId));
          setEditingCardId(null);
        }
      };
      reader.readAsDataURL(file);
    };

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e: Event) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        uploadImage(file);
      }
    };
    input.click();
  };

  const handleCardPdfUpload = (cardId: number, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadPdf = (file: File) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const pdfUrl = event.target?.result as string;
        const card = cards.find(c => c.id === cardId);
        if (card) {
          const newPdf: PDF = {
            id: Date.now(),
            url: pdfUrl,
            position: {
              x: card.position.x + 260,
              y: card.position.y,
            },
            width: 320,  // Initial width
            height: 450, // Initial height
          };
          setPdfs(prevPdfs => [...prevPdfs, newPdf]);
        }
      };
      reader.readAsDataURL(file);
    };

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.onchange = (e: Event) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        uploadPdf(file);
      }
    };
    input.click();
  };

  const finalizeCardCreation = () => {
    if (tempCard) {
      if (tempCard.content.trim() !== '') {
        setCards(prevCards => [...prevCards, tempCard]);
      }
      setTempCard(null);
      setEditingCardId(null);
    }
  };

  const handleImageClick = (e: React.MouseEvent, imageId: number) => {
    e.stopPropagation();
    if (!isDragging) {
      setSelectedImageId(imageId);
      setSelectedCardId(null);
      setEditingCardId(null);
    }
  };

  const handleDeleteImage = () => {
    if (selectedImageId !== null) {
      const imageToDelete = images.find(image => image.id === selectedImageId);
      if (imageToDelete) {
        const deleteImageCommand: Command = {
          undo: () => setImages(prevImages => [...prevImages, imageToDelete]),
          redo: () => setImages(prevImages => prevImages.filter(image => image.id !== selectedImageId))
        };
        addCommand(deleteImageCommand);
        deleteImageCommand.redo();
      }
      setSelectedImageId(null);
    }
  };

  const handleDeleteCard = () => {
    if (selectedCardId !== null) {
      setCards(prevCards => prevCards.filter(card => card.id !== selectedCardId));
      setSelectedCardId(null);
    }
  };

  const wiggleStyle = {
    animation: 'subtleWiggle 0.s ease-in-out infinite',
    };

  const handleCopy = (e: React.ClipboardEvent) => {
    // Only handle structured card/image copying when no text is actively selected
    if (window.getSelection()?.toString()) {
      return; // Let the default copy behavior handle selected text
    }

    e.preventDefault();
    if (selectedCardId !== null) {
      const cardToCopy = cards.find(card => card.id === selectedCardId);
      if (cardToCopy) {
        e.clipboardData.setData('text/plain', JSON.stringify({ type: 'card', data: cardToCopy }));
      }
    } else if (selectedImageId !== null) {
      const imageToCopy = images.find(image => image.id === selectedImageId);
      if (imageToCopy) {
        e.clipboardData.setData('text/plain', JSON.stringify({ type: 'image', data: imageToCopy }));
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const pastedData = e.clipboardData.getData('text');
    
    // Try to parse as JSON first
    try {
      const parsedData = JSON.parse(pastedData);
      if (parsedData.type === 'card' || parsedData.type === 'image') {
        e.preventDefault();
        if (parsedData.type === 'card') {
          const newCard: Card = {
            ...parsedData.data,
            id: Date.now(),
            position: {
              x: parsedData.data.position.x + 20,
              y: parsedData.data.position.y + 20,
            },
          };
          setCards(prevCards => [...prevCards, newCard]);
        } else if (parsedData.type === 'image') {
          const newImage: Image = {
            ...parsedData.data,
            id: Date.now(),
            position: {
              x: parsedData.data.position.x + 20,
              y: parsedData.data.position.y + 20,
            },
          };
          setImages(prevImages => [...prevImages, newImage]);
        }
      }
    } catch (error) {
      // If it's not valid JSON, let the default paste behavior handle it
      return;
    }
  };

  const handleCardContentEdit = (cardId: number, newContent: string) => {
    setCards(prevCards =>
      prevCards.map(card =>
        card.id === cardId ? { ...card, content: newContent } : card
      )
    );
  };

  const handleCardPaste = (e: React.ClipboardEvent<HTMLTextAreaElement>, cardId: number) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    // Allow default paste behavior
  };

  // Add this function near the top of your component
  const preventDefault = (e: Event) => e.preventDefault();

  // Add this new constant for resize handles
  const RESIZE_HANDLES: { [key: string]: ResizeHandle } = {
    'top-left': { cursor: 'nw-resize', position: { top: '0', left: '0' } },
    'top-right': { cursor: 'ne-resize', position: { top: '0', right: '0' } },
    'bottom-left': { cursor: 'sw-resize', position: { bottom: '0', left: '0' } },
    'bottom-right': { cursor: 'se-resize', position: { bottom: '0', right: '0' } },
    'top': { cursor: 'n-resize', position: { top: '0', left: '50%' } },
    'bottom': { cursor: 's-resize', position: { bottom: '0', left: '50%' } },
    'left': { cursor: 'w-resize', position: { left: '0', top: '50%' } },
    'right': { cursor: 'e-resize', position: { right: '0', top: '50%' } },
  };

  // Update handleResizeStart
  const handleResizeStart = (e: React.MouseEvent, id: number, handle: string) => {
    e.stopPropagation();
    const item = cards.find(c => c.id === id) || pdfs.find(p => p.id === id);
    if (item) {
      setResizingCardId(id);
      setResizeStart({
        x: e.clientX,
        y: e.clientY,
        width: ('width' in item && typeof item.width === 'number') ? item.width : 250,
        height: ('height' in item && typeof item.height === 'number') ? item.height : 100,
        handle,
        originalPosition: { x: item.position.x, y: item.position.y }
      });
      setIsResizing(true);
      document.addEventListener('selectstart', preventDefault);
      document.body.style.userSelect = 'none';
    }
  };

  // Update handleResize
  const handleResize = (e: React.MouseEvent) => {
    if (resizingCardId !== null && resizeStart) {
      const dx = (e.clientX - resizeStart.x) / scale;
      const dy = (e.clientY - resizeStart.y) / scale;
      
      let newWidth = resizeStart.width;
      let newHeight = resizeStart.height;
      let newX = resizeStart.originalPosition.x;
      let newY = resizeStart.originalPosition.y;

      // Handle width changes
      if (resizeStart.handle.includes('left')) {
        newWidth = Math.max(150, resizeStart.width - dx);
        newX = resizeStart.originalPosition.x + resizeStart.width - newWidth;
      } else if (resizeStart.handle.includes('right')) {
        newWidth = Math.max(150, resizeStart.width + dx);
      }

      // Handle height changes
      if (resizeStart.handle.includes('top')) {
        newHeight = Math.max(100, resizeStart.height - dy);
        newY = resizeStart.originalPosition.y + resizeStart.height - newHeight;
      } else if (resizeStart.handle.includes('bottom')) {
        newHeight = Math.max(100, resizeStart.height + dy);
      }

      // Update cards
      setCards(prevCards =>
        prevCards.map(card =>
          card.id === resizingCardId
            ? {
                ...card,
                width: newWidth,
                height: newHeight,
                position: { x: newX, y: newY }
              }
            : card
        )
      );

      // Update PDFs
      setPdfs(prevPdfs =>
        prevPdfs.map(pdf =>
          pdf.id === resizingCardId
            ? {
                ...pdf,
                width: newWidth,
                height: newHeight,
                position: { x: newX, y: newY }
              }
            : pdf
        )
      );
    }
  };

  const handleResizeEnd = () => {
    setResizingCardId(null);
    setResizeStart(null);
    setIsResizing(false);
    document.removeEventListener('selectstart', preventDefault);
    document.body.style.userSelect = '';
  };

  // Add this new function to find the parent card
  const findParentCard = (cardId: number): Card | null => {
    const parentLink = links.find(link => link.toId === cardId);
    if (parentLink) {
      return cards.find(card => card.id === parentLink.fromId) || null;
    }
    return null;
  };

  // Add this function to check if a card is a child
  const isChildCard = (cardId: number): boolean => {
    return links.some(link => link.toId === cardId);
  };

  const truncateTitle = (content: string, maxLength: number = 50): string => {
    if (content.length <= maxLength) return content;
    return content.substring(0, maxLength - 3) + '...';
  };

  // Add this new function near your other generate functions
  const generateImage = async (prompt: string): Promise<string | null> => {
    if (!openAIKey) {
      console.log('No OpenAI API key found');
      return null;
    }

    try {
      const response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${openAIKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: "dall-e-3",
          prompt: prompt,
          n: 1,
          size: "1024x1024",
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.data[0].url;
    } catch (error) {
      console.error('Error generating image:', error);
      return null;
    }
  };

  // Modify the handleGenerateClick function
  const handleGenerateClick = async (cardId: number) => {
    console.log(`handleGenerateClick called for cardId: ${cardId}`);
    const card = cards.find(c => c.id === cardId);
    if (!card) return;

    // Check if the content starts with /image
    if (card.content.trim().startsWith('/image')) {
      setGeneratingCardId(cardId);
      const prompt = card.content.replace('/image', '').trim();
      const imageUrl = await generateImage(prompt);
      setGeneratingCardId(null);

      if (imageUrl) {
        // Create a new image
        const newImage: Image = {
          id: Date.now(),
          url: imageUrl,
          position: {
            x: card.position.x + 260,
            y: card.position.y,
          },
          description: prompt
        };
        setImages(prevImages => [...prevImages, newImage]);
        
        // Create a link between the prompt card and the generated image
        setLinks(prevLinks => [...prevLinks, {
          fromId: cardId,
          toId: newImage.id
        }]);
      }
    } else {
      // Handle regular text generation as before
      const fullHistory = getFullConversationHistory(cardId);
      const prompt = fullHistory.join("\n\n");
      
      const generatedContent = selectedModel === 'ollama'
        ? await generateOllamaResponse(cardId, prompt)
        : await generateChatGPTResponse(cardId, prompt);
      
      setGeneratingCardId(null);
      
      if (generatedContent) {
        const newCard: Card = {
          id: Date.now(),
          title: truncateTitle(card.content),
          content: generatedContent,
          position: {
            x: card.position.x + 260,
            y: card.position.y,
          },
          conversationHistory: [...fullHistory, generatedContent],
          width: 250,
          height: 100,
        };
        console.log('Creating new generated card:', newCard);
        setCards(prevCards => [...prevCards, newCard]);
        setLinks(prevLinks => [...prevLinks, { fromId: cardId, toId: newCard.id }]);
        setGeneratedCardIds(prev => new Set(prev).add(newCard.id));
      }
    }
  };

  // Modify the handleAddClick function
  const handleAddClick = (cardId: number) => {
    const card = cards.find(c => c.id === cardId);
    
    if (card) {
      const newCard: Card = {
        id: Date.now(),
        title: truncateTitle(card.content), // Use the parent card's content as the title
        content: "",
        position: {
          x: card.position.x + 260,
          y: card.position.y,
        },
        conversationHistory: [...card.conversationHistory, card.content],
      };
      setCards(prevCards => [...prevCards, newCard]);
      setLinks(prevLinks => [...prevLinks, { fromId: cardId, toId: newCard.id }]);
      setPendingInputCards(prev => new Set(prev).add(newCard.id));
      setEditingCardId(newCard.id);
    }
  };

  // Modify the generateOllamaResponse function
  const generateOllamaResponse = async (cardId: number, prompt: string) => {
    console.log('generateOllamaResponse called for cardId:', cardId);
    console.log('Prompt:', prompt);
    setGeneratingCardId(cardId);
    try {
      console.log('Sending request to Ollama API...');
      const response = await fetch("http://localhost:11434/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: MODEL,
          messages: [
            { role: "system", content: "You are a helpful assistant. You have access to the full conversation history." },
            { role: "user", content: prompt }
          ],
        }),
      });

      if (!response.ok) {
        console.error('Ollama API response not OK:', response.status, response.statusText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Ollama API response received, starting to read stream...');
      const reader = response.body?.getReader();
      let assistantResponse = "";

      while (true) {
        const { done, value } = await reader!.read();
        if (done) {
          console.log('Stream complete');
          break;
        }
        const chunk = new TextDecoder().decode(value);
        console.log('Received chunk:', chunk);
        const lines = chunk.split("\n");
        for (const line of lines) {
          if (line.trim() !== "") {
            const parsedLine = JSON.parse(line);
            if (parsedLine.message?.content) {
              assistantResponse += parsedLine.message.content;
              console.log('Updated assistant response:', assistantResponse);
            }
          }
        }
      }

      console.log('Final response:', assistantResponse);
      return assistantResponse;
    } catch (error) {
      console.error('Detailed error in generateOllamaResponse:', error);
      if (error instanceof Error) {
        console.error('Error stack:', error.stack);
        alert(`Failed to generate response. Error: ${error.message}`);
      } else {
        console.error('Unknown error type:', error);
        alert('Failed to generate response. An unknown error occurred.');
      }
    } finally {
      console.log('Clearing generatingCardId');
      setGeneratingCardId(null);
    }
  };

  // Add this new function to get the full conversation history
  const getFullConversationHistory = (cardId: number): string[] => {
    const history: string[] = [];
    let currentCardId: number | null = cardId;

    while (currentCardId !== null) {
      const currentCard = cards.find(c => c.id === currentCardId);
      if (currentCard) {
        history.unshift(currentCard.content);
        const parentLink = links.find(link => link.toId === currentCardId);
        currentCardId = parentLink ? parentLink.fromId : null;
      } else {
        break;
      }
    }

    return history;
  };

  // Modify the renderLinks function for culling
  const renderLinks = () => {
    return links.map((link, index) => {
      const fromCard = cards.find(c => c.id === link.fromId);
      const fromImage = images.find(i => i.id === link.fromId);
      const toCard = cards.find(c => c.id === link.toId);
      const toImage = images.find(i => i.id === link.toId);
      
      if ((!fromCard && !fromImage) || (!toCard && !toImage)) return null;

      // Get source and destination positions
      let fromPos, toPos, fromSize, toSize;
      
      if (fromCard) {
        fromPos = fromCard.position;
        fromSize = { width: fromCard.width || 250, height: fromCard.height || 100 };
      } else if (fromImage) {
        fromPos = fromImage.position;
        const imageElement = document.querySelector(`img[src="${fromImage.url}"]`) as HTMLImageElement;
        fromSize = { width: imageElement?.width || 300, height: imageElement?.height || 300 };
      } else return null;

      if (toCard) {
        toPos = toCard.position;
        toSize = { width: toCard.width || 250, height: toCard.height || 100 };
      } else if (toImage) {
        toPos = toImage.position;
        const imageElement = document.querySelector(`img[src="${toImage.url}"]`) as HTMLImageElement;
        toSize = { width: imageElement?.width || 300, height: imageElement?.height || 300 };
      } else return null;

      // Cull links that are completely outside viewport
      if (!isElementInViewport(fromPos, fromSize.width, fromSize.height) &&
          !isElementInViewport(toPos, toSize.width, toSize.height)) {
        return null;
      }

      const linkKey = `${link.fromId}-${link.toId}`;

      // Get source coordinates
      let fromX: number, fromY: number;
      if (fromCard) {
        fromX = fromCard.position.x + (fromCard.width || 250) / 2;
        fromY = fromCard.position.y + (fromCard.height || 100) / 2;
      } else if (fromImage) {
        const imageElement = document.querySelector(`img[src="${fromImage.url}"]`) as HTMLImageElement;
        const width = imageElement?.width || 300;
        const height = imageElement?.height || 300;
        fromX = fromImage.position.x + width / 2;
        fromY = fromImage.position.y + height / 2;
      } else {
        return null;
      }

      // Get destination coordinates
      let toX: number, toY: number;
      if (toCard) {
        toX = toCard.position.x + (toCard.width || 250) / 2;
        toY = toCard.position.y + (toCard.height || 100) / 2;
      } else if (toImage) {
        const imageElement = document.querySelector(`img[src="${toImage.url}"]`) as HTMLImageElement;
        const width = imageElement?.width || 300;
        const height = imageElement?.height || 300;
        toX = toImage.position.x + width / 2;
        toY = toImage.position.y + height / 2;
      } else {
        return null;
      }

      if (!staticRectangles[linkKey]) {
        const distance = Math.sqrt(Math.pow(toX - fromX, 2) + Math.pow(toY - fromY, 2));
        const squareCount = Math.max(Math.floor(distance / 10), 10);

        const squares = Array.from({ length: squareCount }, (_, i) => {
          const t = i / (squareCount - 1);
          return {
            t,
            size: Math.random() * 4 + 2,
            color: `hsl(${Math.random() * 360}, 70%, 70%)`,
            rotation: Math.random() * 45,
          };
        });

        setStaticRectangles(prev => ({...prev, [linkKey]: squares}));
      }

      const squares = staticRectangles[linkKey] || [];

      return (
        <svg key={index} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}>
          {squares.map((square, sqIndex) => {
            const x = fromX + (toX - fromX) * square.t;
            const y = fromY + (toY - fromY) * square.t;
            return (
              <rect
                key={sqIndex}
                x={x - square.size / 2}
                y={y - square.size / 2}
                width={square.size}
                height={square.size}
                fill={square.color}
                transform={`rotate(${square.rotation} ${x} ${y})`}
              />
            );
          })}
        </svg>
      );
    });
  };

  // Add this helper function to get connected cards
  const getConnectedCards = (cardId: number): Card[] => {
    // Get cards connected by links (both directions)
    const connectedLinks = links.filter(link => 
      link.fromId === cardId || link.toId === cardId
    );
    
    return connectedLinks.map(link => {
      const connectedId = link.fromId === cardId ? link.toId : link.fromId;
      return cards.find(card => card.id === connectedId);
    }).filter((card): card is Card => card !== undefined);
  };

  // Modify handleGenClick to handle connected cards
  const handleGenClick = async (cardId: number) => {
    console.log(`handleGenClick called for cardId: ${cardId} using ${selectedModel}`);
    const card = cards.find(c => c.id === cardId);
    if (!card) return;

    // Get connected cards
    const connectedCards = getConnectedCards(cardId);
    
    setGeneratingCardId(cardId);
    
    // Build prompt combining content from connected cards
    let prompt = card.content;
    if (connectedCards.length > 0) {
      prompt = `Based on these inputs:\n\n1. ${card.content}\n\n2. ${
        connectedCards.map(c => c.content).join('\n\n')
      }\n\nPlease provide a response that synthesizes this information.`;
    }
    
    const generatedContent = await generateResponse(cardId, prompt);
    
    if (generatedContent) {
      const newCard: Card = {
        id: Date.now(),
        title: 'Synthesis',
        content: generatedContent,
        position: {
          x: card.position.x + 260,
          y: card.position.y,
        },
        conversationHistory: [prompt, generatedContent],
        width: 250,
        height: 100,
      };
      
      setCards(prevCards => [...prevCards, newCard]);
      // Create links from both source cards to the new card
      setLinks(prevLinks => [
        ...prevLinks,
        { fromId: cardId, toId: newCard.id },
        ...connectedCards.map(c => ({ fromId: c.id, toId: newCard.id }))
      ]);
      setGeneratedCardIds(prev => new Set(prev).add(newCard.id));
    }
    
    setGeneratingCardId(null);
  };

  // Add a helper function to handle API selection
  const generateResponse = async (cardId: number, prompt: string) => {
    console.log(`Generating response for cardId: ${cardId} using ${selectedModel}`);
    return selectedModel === 'ollama'
      ? generateOllamaResponse(cardId, prompt)
      : generateChatGPTResponse(cardId, prompt);
  };

  // Modify the ConfigModal component to ensure hooks are called unconditionally
  const ConfigModal = () => {
    const [tempKey, setTempKey] = useState(openAIKey);
    const [tempModel, setTempModel] = useState(selectedModel);

    // Move the useEffect hooks outside of any conditions
    useEffect(() => {
      if (tempKey) {
        localStorage.setItem('openAIKey', tempKey);
      }
    }, [tempKey]);

    useEffect(() => {
      localStorage.setItem('selectedModel', tempModel);
    }, [tempModel]);

    const handleSave = () => {
      setOpenAIKey(tempKey);
      setSelectedModel(tempModel);
      setShowConfigModal(false);
    };

    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}>
        <div style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '400px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}>
          {/* Model Selection */}
          <div style={{ marginBottom: '20px' }}>
            <label style={{ 
              display: 'block', 
              marginBottom: '8px',
              fontFamily: 'Georgia, "Times New Roman", Times, serif',
            }}>
              Model:
            </label>
            <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <button
                  onClick={() => setTempModel('openai')}
                  style={{
                    padding: '8px 16px',
                    borderRadius: '4px',
                    border: '1px solid black',
                    backgroundColor: tempModel === 'openai' ? '#f0f0f0' : 'white',
                    cursor: 'pointer',
                    fontFamily: 'Georgia, "Times New Roman", Times, serif',
                    flex: 1,
                    color: 'black',
                  }}
                >
                  OpenAI
                </button>
                <button
                  onClick={() => setTempModel('ollama')}
                  style={{
                    padding: '8px 16px',
                    borderRadius: '4px',
                    border: '1px solid black',
                    backgroundColor: tempModel === 'ollama' ? '#f0f0f0' : 'white',
                    cursor: 'pointer',
                    fontFamily: 'Georgia, "Times New Roman", Times, serif',
                    flex: 1,
                    color: 'black',
                  }}
                >
                  Ollama
                </button>
              </div>
              {tempModel === 'ollama' && (
                <div style={{
                  fontSize: '12px',
                  color: '#666',
                  fontStyle: 'italic',
                  textAlign: 'center',
                  marginTop: '4px',
                }}>
                  Only works locally, disregard for now...
                </div>
              )}
            </div>
          </div>

          {/* OpenAI Key Input (only show if OpenAI is selected) */}
          {tempModel === 'openai' && (
            <div style={{ marginBottom: '20px' }}>
              <label style={{ 
                display: 'block', 
                marginBottom: '8px',
                fontFamily: 'Georgia, "Times New Roman", Times, serif',
              }}>
                OpenAI API Key:
              </label>
              <input
                type="password"
                value={tempKey}
                onChange={(e) => setTempKey(e.target.value)}
                onKeyDown={(e) => {
                  // Stop propagation of keyboard events to prevent interference
                  e.stopPropagation();
                }}
                onPaste={(e) => {
                  // Stop propagation of paste event
                  e.stopPropagation();
                }}
                style={{
                  width: '95%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  fontFamily: 'Georgia, "Times New Roman", Times, serif',
                }}
              />
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <button
              onClick={() => setShowConfigModal(false)}
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                backgroundColor: 'white',
                cursor: 'pointer',
                fontFamily: 'Georgia, "Times New Roman", Times, serif',
                color: 'black',
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: '1px solid black',
                backgroundColor: 'white',
                cursor: 'pointer',
                fontFamily: 'Georgia, "Times New Roman", Times, serif',
                color: 'black',
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Add this new function near your other generate functions
  const generateChatGPTResponse = async (cardId: number, prompt: string) => {
    console.log('generateChatGPTResponse called for cardId:', cardId);
    console.log('Prompt:', prompt);
    
    if (!openAIKey) {
            console.log('No OpenAI API key found');
      return `Please set up your OpenAI API key first:
1. Click the Config button in the bottom left
2. Enter your OpenAI API key
3. Click Save
4. Try generating again

You can get an API key at: https://platform.openai.com/api-keys`;
    }

    setGeneratingCardId(cardId);
    try {
      console.log('Sending request to OpenAI API...');
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${openAIKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: prompt }
          ],
          temperature: 0.7
        })
      });

      if (!response.ok) {
        console.error('OpenAI API response not OK:', response.status, response.statusText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('OpenAI API response received');
      const data = await response.json();
      console.log('OpenAI API response data:', data);
      return data.choices[0].message.content;
    } catch (error) {
      console.error('Error calling OpenAI:', error);
      if (error instanceof Error) {
        console.error('Error stack:', error.stack);
      }
      return `Error: Failed to generate response. ${error instanceof Error ? error.message : 'Unknown error occurred'}`;
    } finally {
      console.log('Clearing generatingCardId');
      setGeneratingCardId(null);
    }
  };

  // Add this new function near your other generate functions
  const generateImageQuestion = async (imageId: number, question: string) => {
    const image = images.find(img => img.id === imageId);
    if (!image) return;

    try {
      // Convert data URL to base64 string
      const base64Image = image.url.split(',')[1];
      
      const messages: VisionMessage[] = [
        {
          role: "user",
          content: [
            { type: "text", text: question },
            {
              type: "image_url",
              image_url: {
                url: `data:image/jpeg;base64,${base64Image}`
              }
            }
          ]
        }
      ];

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${openAIKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: "gpt-4-vision-preview",
          messages,
          max_tokens: 300
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error('Error generating vision response:', error);
      return `Error analyzing image: ${error instanceof Error ? error.message : 'Unknown error occurred'}`;
    }
  };

  // Add this new function to handle starting a line draw
  const handleStartDrawing = (e: React.MouseEvent, cardId: number) => {
    if (e.metaKey || e.ctrlKey) {  // Check for cmd/ctrl instead of alt
      e.stopPropagation();
      setDrawingState({
        isDrawing: true,
        startCardId: cardId,
        endPosition: {
          x: (e.clientX - pan.x) / scale,
          y: (e.clientY - pan.y) / scale
        }
      });
    }
  };

  // Add this function to handle completing the line drawing
  const handleCardDrawEnd = (e: React.MouseEvent, endCardId: number) => {
    if (drawingState.isDrawing && drawingState.startCardId !== null) {
      e.stopPropagation();
      // Don't create self-referential links
      if (drawingState.startCardId !== endCardId) {
        setLinks(prevLinks => [...prevLinks, {
          fromId: drawingState.startCardId!,
          toId: endCardId
        }]);
      }
      setDrawingState({
        isDrawing: false,
        startCardId: null,
        endPosition: null
      });
    }
  };

  // Add this new component to render the temporary drawing line
  const DrawingLine = () => {
    if (!drawingState.isDrawing || !drawingState.startCardId || !drawingState.endPosition) {
      return null;
    }

    const startCard = cards.find(card => card.id === drawingState.startCardId);
    if (!startCard) return null;

    const startX = startCard.position.x + (startCard.width || 250) / 2;
    const startY = startCard.position.y + (startCard.height || 100) / 2;

    return (
      <svg
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      >
        <line
          x1={startX}
          y1={startY}
          x2={drawingState.endPosition.x}
          y2={drawingState.endPosition.y}
          stroke="#ffafcc"
          strokeWidth="2"
          strokeDasharray="5,5"
        />
      </svg>
    );
  };

  // Update this CSS in the style tag within the return statement
  const gridStyles = `
    .canvas {
      background-image: 
        /* Cross marks at 18x18 intersections */
        radial-gradient(circle at center, rgba(0, 0, 0, 0.3) 2px, transparent 2px),
        /* Plus marks at 9x9 intersections */
        radial-gradient(circle at center, rgba(0, 0, 0, 0.2) 1px, transparent 1px),
        /* Regular grid lines */
        linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
      background-size: 
        360px 360px,  /* Cross marks (18 * 20px) */
        180px 180px,  /* Plus marks (9 * 20px) */
        20px 20px,    /* Regular grid */
        20px 20px;    /* Regular grid */
      background-position:
        0 0,          /* Cross marks */
        0 0,          /* Plus marks */
        0 0,          /* Regular grid */
        0 0;          /* Regular grid */
    }
  `;

  return (
    <>
      <GoogleAnalytics />
      <div 
        ref={appRef}
        className="App" 
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onKeyDown={handleKeyDown}
        onCopy={handleCopy}
        onPaste={handlePaste}
        tabIndex={0}
      >
        <style>
          {`
            @keyframes cardAppear {
              from {
                opacity: 0;
                transform: scale(0.9);
              }
              to {
                opacity: 1;
                transform: scale(1);
              }
            }

            @keyframes wiggle {
              0% { transform: rotate(0deg); }
              25% { transform: rotate(-1deg); }
              75% { transform: rotate(1deg); }
              100% { transform: rotate(0deg); }
            }

            .card {
              transition: box-shadow 0.3s ease-in-out;
            }
            .card:hover {
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            }

            ${gridStyles}
          `}
        </style>

        <div className="canvas-container" style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          overflow: 'hidden',
          backgroundColor: 'black', // Add this line
        }}>
          <div 
            className="canvas"
            style={{
              transform: `translate(${pan.x}px, ${pan.y}px) scale(${scale})`,
              transformOrigin: '0 0',
              border: '2px dotted #e5e5e5',
              borderRadius: '24px',
              position: 'absolute',
              top: '0',
              left: '0',
              width: '5000px',
              height: '5000px',
              minWidth: '5000px',
              minHeight: '5000px',
              padding: '24px',
              backgroundColor: '#fff',
            }}
            onClick={handleCanvasClick}
            onDoubleClick={handleCanvasDoubleClick}
          >
            {renderLinks()}
            <DrawingLine />
            {cards.filter(card => isElementInViewport(card.position, card.width || 250, card.height || 100))
              .map(card => (
                <div 
                  key={card.id}
                  id={`card-${card.id}`}
                  className={`card ${selectedItems.has(card.id) ? 'selected' : ''}`}
                  style={{
                    position: 'absolute',
                    left: `${card.position.x}px`,
                    top: `${card.position.y}px`,
                    cursor: selectedItems.has(card.id) && isDragging ? 'grabbing' : 'grab',
                    padding: '10px',
                    backgroundColor: 'white',
                    border: selectedItems.has(card.id) ? '1px solid #ffafcc' : '1px solid #ccc',
                    borderRadius: '12px',
                    width: card.width ? `${card.width}px` : '250px',
                    height: card.height ? `${card.height}px` : '100px',
                    minHeight: '80px',
                    transformOrigin: 'center center',
                    ...(selectedItems.has(card.id) && isDragging ? {
                      animation: 'subtleWiggle 0.3s ease-in-out infinite'
                    } : {}),
                    display: 'flex',
                    flexDirection: 'column',
                    userSelect: 'none',
                    animation: draggedCardId === card.id 
                      ? 'wiggle 0.3s ease-in-out infinite' 
                      : newCardId === card.id 
                        ? 'cardAppear 0.1s ease-out' 
                        : undefined,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s ease-in-out',
                    overflow: 'hidden',
                  }}
                  onMouseDown={(e: React.MouseEvent) => {
                    handleMouseDown(e, card.id);
                    handleStartDrawing(e, card.id);
                  }}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setSelectedCardId(card.id);
                    setFocusedCardId(card.id);
                  }}
                  onDoubleClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setEditingCardId(card.id);
                    setTempCardContent(card.content);
                  }}
                  onMouseEnter={(e) => {
                    setHoveredCardId(card.id);
                    handleCardDrawEnd(e, card.id);
                  }}
                  onMouseLeave={() => setHoveredCardId(null)}
                  onFocus={() => setFocusedCardId(card.id)}
                  onBlur={() => setFocusedCardId(null)}
                  tabIndex={0}
                >
                  {card.title && (
                    <div
                      style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                        color: '#6d6875', // Changed to a dark pink color (Deep Pink)
                        marginBottom: '8px',
                        marginLeft: '12px',
                        marginRight: '12px',
                        marginTop: '8px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        const parentCard = findParentCard(card.id);
                        if (parentCard) {
                          const parentElement = document.getElementById(`card-${parentCard.id}`);
                          if (parentElement) {
                            parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          }
                          setSelectedCardId(parentCard.id);
                        }
                      }}
                    >
                      {card.title}
                    </div>
                  )}
                  {editingCardId === card.id ? (
                    <div 
                      onClick={(e) => e.stopPropagation()} 
                      onMouseDown={(e) => e.stopPropagation()}
                      style={{ 
                        flex: 1, 
                        position: 'relative',
                        userSelect: 'text',
                        height: 'calc(100% - 40px)',
                        display: 'flex',
                        backgroundColor: '#fff',
                      }}
                    >
                      <textarea
                        ref={textareaRef}
                        value={tempCardContent}
                        onChange={handleCardContentChange}
                        onKeyDown={(e) => handleCardContentKeyDown(e, card.id)}
                        onPaste={(e) => {
                          e.stopPropagation();
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                        onFocus={() => {
                          setActiveInputCardId(card.id);
                          setEditingCardId(card.id);
                        }}
                        onBlur={(e) => {
                          setActiveInputCardId(null);
                          handleCardBlur(e);
                        }}
                        placeholder="Type... (Markdown supported)"
                        style={{ 
                          width: '100%',
                          height: '100%',
                          padding: '8px',
                          border: 'none',
                          resize: 'none',
                          outline: 'none',
                          fontFamily: 'Georgia, "Times New Roman", Times, serif',
                          fontSize: '14px',
                          userSelect: 'text',
                          WebkitUserSelect: 'text',
                          MozUserSelect: 'text',
                          msUserSelect: 'text',
                          cursor: 'text',
                          backgroundColor: 'white',
                        }}
                        autoFocus
                      />
                    </div>
                  ) : (
                    <div style={{ position: 'relative', flex: 1, overflowY: 'auto' }}>
                      <div 
                        style={{ 
                          wordWrap: 'break-word', 
                          overflowWrap: 'break-word',
                          maxWidth: '100%',
                          marginTop: '8px',
                          marginBottom: '8px',
                          marginLeft: '12px',
                          marginRight: '12px',
                        }}
                        dangerouslySetInnerHTML={{ __html: renderedContents[card.id] || '' }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: '20px',
                          background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
                          pointerEvents: 'none',
                        }}
                      />
                    </div>
                  )}
                  <div 
                    style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      marginTop: 'auto',
                      position: 'absolute',
                      bottom: '12px',
                      left: '12px',
                      right: '12px',
                      opacity: hoveredCardId === card.id ? 1 : 0,
                      transition: 'opacity 0.3s ease-in-out',
                      animation: hoveredCardId === card.id ? 'fadeIn 0.3s ease-in-out' : 'none',
                    }}
                  >
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCardImageUpload(card.id, e);
                        }}
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid black',
                          padding: '4px 4px',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: '0 2px 0 rgba(0, 0, 0, 0.2)',
                          fontFamily: 'Georgia, "Times New Roman", Times, serif',
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img 
                          src="/image.svg" 
                          alt="Upload Image" 
                          style={{
                            width: '18px',
                            height: '18px',
                          }}
                        />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCardPdfUpload(card.id, e);
                        }}
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid black',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: '0 2px 0 rgba(0, 0, 0, 0.2)',
                          fontFamily: 'Georgia, "Times New Roman", Times, serif',
                          fontSize: '14px',
                        }}
                      >
                        PDF
                      </button>
                    </div>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      {editingCardId !== card.id && (
                        <>
                          {(isChildCard(card.id) || generatedCardIds.has(card.id)) && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleGenClick(card.id);
                              }}
                              disabled={generatingCardId === card.id}
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                border: '1px solid black',
                                padding: '5px 10px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                boxShadow: '0 2px 0 rgba(0, 0, 0, 0.2)',
                                fontFamily: 'Georgia, "Times New Roman", Times, serif',
                                fontSize: '14px',
                              }}
                            >
                              {generatingCardId === card.id ? 'Generating...' : 'Gen'}
                            </button>
                          )}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isChildCard(card.id) || generatedCardIds.has(card.id)) {
                                handleAddClick(card.id);
                              } else {
                                handleGenerateClick(card.id);
                              }
                            }}
                            disabled={generatingCardId === card.id}
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid black',
                              padding: '5px 10px',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: '0 2px 0 rgba(0, 0, 0, 0.2)',
                              fontFamily: 'Georgia, "Times New Roman", Times, serif',
                              fontSize: '14px',
                            }}
                          >
                            {isChildCard(card.id) || generatedCardIds.has(card.id) ? 'Add' : (generatingCardId === card.id ? 'Generating...' : 'Generate')}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  {Object.entries(RESIZE_HANDLES).map(([handle, config]) => (
                    <div
                      key={handle}
                      style={{
                        position: 'absolute',
                        width: '10px',
                        height: '10px',
                        cursor: config.cursor,
                        ...config.position,
                        background: 'transparent',
                        zIndex: 2,
                      }}
                      onMouseDown={(e) => handleResizeStart(e, card.id, handle)}
                    />
                  ))}
                </div>
              ))}

            {images.filter(image => {
              const imageElement = document.querySelector(`img[src="${image.url}"]`) as HTMLImageElement;
              const width = imageElement?.width || 300;
              const height = imageElement?.height || 300;
              return isElementInViewport(image.position, width, height);
            }).map(image => (
              <div
                key={image.id}
                className={selectedItems.has(image.id) ? 'selected' : ''}
                style={{
                  position: 'absolute',
                  left: `${image.position.x}px`,
                  top: `${image.position.y}px`,
                  cursor: selectedItems.has(image.id) && isDragging ? 'grabbing' : 'grab',
                  border: selectedItems.has(image.id) ? '1px solid #ffafcc' : 'none',
                }}
                onMouseDown={(e) => handleMouseDown(e, image.id)}
                onClick={(e) => e.stopPropagation()}
                onMouseEnter={() => setHoveredImageId(image.id)}
                onMouseLeave={() => setHoveredImageId(null)}
              >
                <img
                  src={image.url}
                  alt="Uploaded"
                  style={{
                    maxWidth: '300px',
                    maxHeight: '300px',
                    pointerEvents: 'none',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    display: 'flex',
                    gap: '8px',
                    opacity: hoveredImageId === image.id ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  {/* Find the source card that generated this image */}
                  {links.some(link => {
                    const sourceCard = cards.find(card => card.id === link.fromId);
                    return link.toId === image.id && sourceCard?.content?.trim().startsWith('/image');
                  }) ? (
                    <button
                      onClick={async (e) => {
                        e.stopPropagation();
                        // Find the original prompt card
                        const link = links.find(l => l.toId === image.id);
                        const promptCard = cards.find(c => c.id === link?.fromId);
                        if (promptCard) {
                          const prompt = promptCard.content.replace('/image', '').trim();
                          const newImageUrl = await generateImage(prompt);
                          if (newImageUrl) {
                            const newImage: Image = {
                              id: Date.now(),
                              url: newImageUrl,
                              position: {
                                x: image.position.x + 20,
                                y: image.position.y + 20,
                              },
                              description: prompt
                            };
                            setImages(prevImages => [...prevImages, newImage]);
                            // Link the new image to the original prompt card
                            setLinks(prevLinks => [...prevLinks, {
                              fromId: promptCard.id,
                              toId: newImage.id
                            }]);
                          }
                        }
                      }}
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid black',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.2)',
                        fontFamily: 'Georgia, "Times New Roman", Times, serif',
                        fontSize: '14px',
                      }}
                    >
                      Generate
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          // Create a new card for asking questions with image context
                          const newCard: Card = {
                            id: Date.now(),
                            content: "Ask a question about this image...",
                            position: {
                              x: image.position.x + 260,
                              y: image.position.y,
                            },
                            conversationHistory: [`This conversation is about an image. When you respond, reference the image that was uploaded.`],
                            width: 250,
                            height: 100,
                          };
                          setCards(prevCards => [...prevCards, newCard]);
                          setLinks(prevLinks => [...prevLinks, { fromId: image.id, toId: newCard.id }]);
                          setEditingCardId(newCard.id);
                          setPendingInputCards(prev => new Set(prev).add(newCard.id));
                        }}
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid black',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: '0 2px 0 rgba(0, 0, 0, 0.2)',
                          fontFamily: 'Georgia, "Times New Roman", Times, serif',
                          fontSize: '14px',
                        }}
                      >
                        Ask
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          analyzeExistingImage(image.id);
                        }}
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '1px solid black',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          cursor: interpretingImageId === image.id ? 'wait' : 'pointer',
                          boxShadow: '0 2px 0 rgba(0, 0, 0, 0.2)',
                          fontFamily: 'Georgia, "Times New Roman", Times, serif',
                          fontSize: '14px',
                        }}
                      >
                        {interpretingImageId === image.id ? 'Interpreting...' : 'Interpret'}
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}

            {pdfs.filter(pdf => isElementInViewport(pdf.position, pdf.width, pdf.height))
              .map(pdf => (
                <div
                  key={pdf.id}
                  className={selectedItems.has(pdf.id) ? 'selected' : ''}
                  style={{
                    position: 'absolute',
                    left: `${pdf.position.x}px`,
                    top: `${pdf.position.y}px`,
                    cursor: selectedItems.has(pdf.id) && isDragging ? 'grabbing' : 'grab',
                    border: selectedItems.has(pdf.id) ? '2px solid #ffafcc' : '2px solid #ccc',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    padding: '10px',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    width: `${pdf.width}px`,
                    height: `${pdf.height}px`,
                  }}
                  onMouseDown={(e) => handleMouseDown(e, pdf.id)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <iframe
                    src={pdf.url}
                    title="PDF Viewer"
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      borderRadius: '4px',
                    }}
                  />
                  {Object.entries(RESIZE_HANDLES).map(([handle, config]) => (
                    <div
                      key={handle}
                      style={{
                        position: 'absolute',
                        width: '10px',
                        height: '10px',
                        cursor: config.cursor,
                        ...config.position,
                        background: 'transparent',
                        zIndex: 2,
                      }}
                      onMouseDown={(e) => handleResizeStart(e, pdf.id, handle)}
                    />
                  ))}
                </div>
              ))}
          </div>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImageUpload}
          accept="image/*"
        />
        <button
          onClick={() => setShowConfigModal(true)}
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            padding: '8px 16px',
            borderRadius: '4px',
            border: '1px solid black',
            backgroundColor: 'white',
            cursor: 'pointer',
            fontFamily: 'Georgia, "Times New Roman", Times, serif',
            zIndex: 1000,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: 'black', // Add this to ensure text is visible
          }}
        >
          <span>Model</span>
          <span style={{
            fontSize: '12px',
            color: '#666',
            borderLeft: '1px solid #ccc',
            paddingLeft: '8px',
          }}>
            {selectedModel === 'openai' ? 'OpenAI' : 'Ollama'}
          </span>
        </button>
        {showConfigModal && <ConfigModal />}
      </div>
    </>
  );
}

export default App;
